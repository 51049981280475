import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Morskie Wypożyczalnie
			</title>
			<meta name={"description"} content={"WITAJ W Morskie Wypozyczalnie, TWÓJ DOM NA WSPANIAŁE I NIEDROŻNE MORSKIE PRZYGODY"} />
			<meta property={"og:title"} content={"Morskie Wypożyczalnie"} />
			<meta property={"og:description"} content={"WITAJ W Morskie Wypozyczalnie, TWÓJ DOM NA WSPANIAŁE I NIEDROŻNE MORSKIE PRZYGODY"} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1520670255513-79161a36e39c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1520670255513-79161a36e39c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1520670255513-79161a36e39c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1520670255513-79161a36e39c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1520670255513-79161a36e39c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1520670255513-79161a36e39c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1520670255513-79161a36e39c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Sprawdź nasze oferty
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					W Morskie Wypozyczalnie wierzymy, że każdy rejs powinien być tak wyjątkowy, jak nasi goście. Nasza pełna gama usług została zaprojektowana tak, aby zaspokoić wszystkie Twoje potrzeby, zapewniając płynne i wzbogacające doświadczenia żeglarskie. Od spersonalizowanych planów podróży po najwyższej klasy udogodnienia na pokładzie – gwarantujemy podróż, która będzie czymś więcej niż tylko wycieczką, ale niezapomnianą.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/1-2.jpg?v=2024-06-12T07:35:51.324Z) bottom/cover no-repeat scroll padding-box"
				lg-min-height="400px"
				lg-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 75%/cover no-repeat scroll padding-box"
				md-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 100%/cover no-repeat scroll padding-box"
				sm-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 50% 100%/cover no-repeat scroll padding-box"
			/>
		</Section>
		<Section
			padding="60px 0 60px 0"
			md-padding="80px 0 80px 0"
			background="--color-darkL1"
			color="--dark"
			quarkly-title="Reviews-14"
		>
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			<Text margin="0px 0px 15px 0px" color="--light" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center">
				Podstawowe usługi
			</Text>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="normal 300 22px/1.5 --fontFamily-sansHelvetica" text-align="center">
				Nasza flota stanowi rdzeń naszej oferty i obejmuje różnorodną gamę statków, które łączą klasyczny urok z nowoczesnym komfortem. Każdy jacht wyposażony jest w najnowocześniejszą technologię nawigacyjną i jest luksusowo wyposażony, aby zapewnić komfortową i bezpieczną podróż.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Niestandardowe plany podróży: dostosuj swoją podróż, uwzględniając ulubione miejsca na wybrzeżu lub pozwól nam zaskoczyć Cię ukrytymi perełkami.{"\n"}
							<br />
							<br />
							{"\n"}Wycieczki jednodniowe: idealne dla tych, którzy chcą odpocząć przez jeden dzień.
							<br />
							<br />
							Rejsy o zachodzie słońca: idealne na romantyczne wieczory lub wyjątkowe święta.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Karting na pokładzie: ciesz się wykwintną kuchnią z menu dostosowanym do Twoich preferencji.
							<br />
							<br />
							{"\n\n"}Lokalne przysmaki: Posmakuj smaków lokalnej kuchni, przygotowanych przez mistrzów kuchni.{"\n"}
							<br />
							<br />
							{"\n"}Życzenia specjalne: od ograniczeń dietetycznych po świąteczne ciasta – poradzimy sobie ze wszystkim.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Wydarzenia: Zamień zwykłą wycieczkę w imponujące wydarzenie, niezależnie od tego, czy będą to urodziny, rocznica czy wycieczka firmowa.{"\n"}
							<br />
							<br />
							{"\n"}Wystrój i oprawa: Dekoracje indywidualne w zależności od wydarzenia.{"\n"}
							<br />
							<br />
							{"\n"}Opcje rozrywki, od muzyki na żywo po profesjonalną fotografię, uatrakcyjnią Twoje wydarzenie.
						</Text>
					</Box>
				</Box>
			</Box>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="normal 300 22px/1.5 --fontFamily-sansHelvetica" text-align="center">
				Ta recenzja jest tylko przypomnieniem naszej oferty w Morskie Wypozyczalnie. Skontaktuj się z naszym zespołem, aby uzyskać pełny zakres naszych usług i spersonalizować swoją kolejną morską przygodę. Poznaj pełną ofertę naszej oferty kontaktując się z nami i pozwól nam stworzyć dla Ciebie idealną wyprawę nad morze.
			</Text>
		</Section>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});